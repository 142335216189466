import Navbar from '../components/Navbar/Navbar';
import NavbarLaLibre from '../components/Navbar/NavbarLaLibre';
import NavbarDH from '../components/Navbar/NavbarDH';

export interface IBrand {
  tld: string;
  branding: string;
  uri: string;
  profilePage: string;
  navbar: any;
  didomi?: string;
  pianoConsent: boolean;
  GTM: string | null;
  cookie: string;
  pianoIdUrl?: {
    sandbox: string;
    production: string;
  };
  confirmation?: {
    title: string;
    content: string;
  };
}

export interface IBrands {
  jde: IBrand;
  telepocket: IBrand;
  moustique: IBrand;
  lavenir: IBrand;
  dhnet: IBrand;
  lalibre: IBrand;
  parismatch: IBrand;
}

const brands: IBrands = Object.freeze({
  jde: {
    tld: 'lejde.be',
    branding: 'jde',
    uri: 'https://www.lejde.be',
    profilePage: 'https://www.lejde.be',
    didomi: '3CRKBGtV',
    pianoConsent: false,
    GTM: 'GTM-W9ZS4SQ',
    cookie: 'Kcr4e5G3S9bBPc5G',
    confirmation: {
      title: 'Inscrivez-vous à notre newsletter !',
      content:
        "Notre rédaction vous propose une newsletter gratuite. Inscrivez-vous dès maintenant pour la recevoir dans votre boîte mail et ainsi rester au courant de toute l'actu.",
    },
    navbar: Navbar,
  },
  telepocket: {
    tld: 'telepocket.be',
    branding: 'telepocket',
    uri: 'https://www.telepocket.be',
    profilePage: 'https://www.telepocket.be/centre-de-profil',
    didomi: 'GDzATAYM',
    pianoConsent: false,
    cookie: 'Kcr4e5G3S9bBPc5G',
    GTM: 'GTM-T3ZGST8',
    navbar: Navbar,
  },
  moustique: {
    tld: 'moustique.lalibre.be|moustique.be',
    branding: 'moustique',
    uri: 'https://www.moustique.be',
    profilePage: 'https://www.moustique.be/centre-de-profil',
    didomi: 'ZYyaCaM7',
    pianoConsent: false,
    GTM: 'GTM-WLLX9Z9',
    cookie: 'Kcr4e5G3S9bBPc5G',
    confirmation: {
      title: 'Inscrivez-vous à notre newsletter !',
      content:
        "Notre rédaction vous propose une newsletter gratuite. Inscrivez-vous dès maintenant pour la recevoir dans votre boîte mail et ainsi rester au courant de toute l'actu.",
    },
    navbar: Navbar,
  },
  lavenir: {
    tld: 'lavenir.net',
    branding: 'lavenir',
    uri: 'https://www.lavenir.net',
    didomi: 'XRnPWTYe',
    pianoConsent: true,
    profilePage: 'https://www.lavenir.net/profil',
    cookie: 'Kcr4e5G3S9bBPc5G',
    GTM: 'GTM-WLLX9Z9',
    pianoIdUrl: {
      sandbox: 'https://id.sandbox.lavenir.net',
      production: 'https://id.lavenir.net',
    },
    confirmation: {
      title: 'Gérez maintenant vos préférences !',
      content:
        'Nos journalistes vous proposent de nombreux rendez-vous thématiques tout au long de la semaine.' +
        ' Rendez vous dans votre espace personnel et faites votre choix.',
    },
    navbar: Navbar,
  },
  dhnet: {
    tld: 'dhnet.be',
    branding: 'dhnet',
    uri: 'https://www.dhnet.be',
    didomi: 'mmTkkHYy',
    pianoConsent: true,
    profilePage: 'https://services.dhnet.be/profil',
    GTM: 'GTM-WNKZC7R',
    cookie: '4QotAuo9P5003YiB',
    pianoIdUrl: {
      sandbox: 'https://id.sandbox.dhnet.be',
      production: 'https://id.dhnet.be',
    },
    confirmation: {
      title: 'Gérez maintenant vos préférences !',
      content:
        'Nos journalistes vous proposent de nombreux rendez-vous thématiques tout au long de la semaine.' +
        ' Rendez vous dans votre espace personnel et faites votre choix.',
    },
    navbar: NavbarDH,
  },
  lalibre: {
    tld: 'lalibre.be',
    branding: 'lalibre',
    didomi: 'YYqWs4N6',
    pianoConsent: true,
    uri: 'https://www.lalibre.be',
    profilePage: 'https://services.lalibre.be/profil/',
    GTM: 'GTM-PBMTTLQ',
    cookie: 'KwM799SnH9J5QyGo',
    pianoIdUrl: {
      sandbox: 'https://id.sandbox.lalibre.be',
      production: 'https://id.lalibre.be',
    },
    confirmation: {
      title: 'Gérez maintenant vos préférences !',
      content:
        'Nos journalistes vous proposent de nombreux rendez-vous thématiques tout au long de la semaine. Rendez vous dans votre espace personnel et faites votre choix.',
    },
    navbar: NavbarLaLibre,
  },
  parismatch: {
    tld: 'parismatche.be',
    branding: 'parismatch',
    uri: 'https://www.parismatch.be',
    profilePage: 'https://www.parismatch.be/centre-de-profil',
    didomi: 'ZYyaCaM7',
    pianoConsent: false,
    GTM: 'GTM-WLLX9Z9',
    cookie: 'Kcr4e5G3S9bBPc5G',
    confirmation: {
      title: 'Inscrivez-vous à notre newsletter !',
      content:
        "Notre rédaction vous propose une newsletter gratuite. Inscrivez-vous dès maintenant pour la recevoir dans votre boîte mail et ainsi rester au courant de toute l'actu.",
    },
    navbar: Navbar,
  },
});

export const getCurrentBrand = (name: string): IBrand =>
  brands?.[name as keyof IBrands] as IBrand;

const MAIN_BRANDS = [brands.lalibre, brands.lavenir, brands.dhnet];
export const MAIN_BRANDS_NAME = MAIN_BRANDS.map((brand) => brand.branding);

export default brands;
