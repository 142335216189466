import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import fetch from 'isomorphic-fetch';
import { GeneralTypes } from '../types';
import { getAPIUrl } from '../generalActions';
import brands from '../../utils/brands';

export type GetBrandType = () => any;

export const GetBrand: GetBrandType =
  () => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    let branding;
    const parts = window.location.hostname.split('.');
    const sndleveldomain = parts
      .slice(-3)
      .filter((e: string) => !e.includes('aboshop'))
      .join('.');
    switch (sndleveldomain) {
      case brands.jde.tld:
        branding = brands.jde.branding;
        break;
      case brands.telepocket.tld:
        branding = brands.telepocket.branding;
        break;
      case sndleveldomain.match(brands.moustique.branding)?.input:
        branding = brands.moustique.branding;
        break;
      case brands.lavenir.tld:
        branding = brands.lavenir.branding;
        break;
      case brands.dhnet.tld:
        branding = brands.dhnet.branding;
        break;
      case brands.lalibre.tld:
        branding = brands.lalibre.branding;
        break;
      case brands.parismatch.tld:
        branding = brands.parismatch.branding;
        break;
      default:
        branding = brands.parismatch.branding;
    }
    const resp = await fetch(
      `${getAPIUrl()}/Product/GetBrand?brandName=${branding}`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }
    );
    const data = await resp.json();
    if (!resp.ok) throw new Error(data.message);

    dispatch({ type: GeneralTypes.SET_BRAND, data });
    return data;
  };
